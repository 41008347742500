@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply px-6 py-2 bg-primary text-black rounded font-medium hover:text-white focus:text-white flex place-items-center justify-center gap-3;
  }

  .screen-body {
    min-height: calc(100vh - 194px);
  }

  .input-text {
    @apply p-3 w-full border border-black;
  }
}

html {
  scroll-behavior: smooth;
}
